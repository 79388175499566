// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-is-amazon-actually-giving-you-the-best-price-index-js": () => import("./../../../src/pages/blog/is-amazon-actually-giving-you-the-best-price/index.js" /* webpackChunkName: "component---src-pages-blog-is-amazon-actually-giving-you-the-best-price-index-js" */),
  "component---src-pages-blog-the-genius-trick-every-target-shopper-needs-to-know-index-js": () => import("./../../../src/pages/blog/the-genius-trick-every-target-shopper-needs-to-know/index.js" /* webpackChunkName: "component---src-pages-blog-the-genius-trick-every-target-shopper-needs-to-know-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-offer-2-index-js": () => import("./../../../src/pages/lp/offer-2/index.js" /* webpackChunkName: "component---src-pages-lp-offer-2-index-js" */),
  "component---src-pages-lp-offer-capitalone-index-js": () => import("./../../../src/pages/lp/offer-capitalone/index.js" /* webpackChunkName: "component---src-pages-lp-offer-capitalone-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-ty-thank-you-index-js": () => import("./../../../src/pages/ty/thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-thank-you-index-js" */)
}

